<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.407945 5.64973L8.3326 0.168016C8.65651 -0.0560053 9.08527 -0.0560053 9.40918 0.168016L17.334 5.64973C17.5894 5.82644 17.7419 6.11729 17.7419 6.42789V15.0536C17.7419 15.5762 17.3182 16 16.7957 16H0.946235C0.423617 16 0 15.5762 0 15.0536V6.42789C0 6.11729 0.152462 5.82644 0.407945 5.64973ZM0.685012 15.4662C0.482148 15.2809 0.354895 15.0141 0.354895 14.7177V6.91125C0.354895 6.32796 0.956168 5.9623 1.4394 6.25167L8.6652 10.6871L0.766994 15.417H0.766948L0.692644 15.4616L0.685012 15.4662ZM8.77888 10.7569L8.77899 10.7569L16.302 6.25171C16.42 6.18107 16.545 6.14945 16.6666 6.1505C17.0431 6.15387 17.3869 6.47037 17.3869 6.91163V14.7232C17.3869 15.2826 16.9603 15.736 16.4268 15.7364C14.7018 15.7378 10.9467 15.7404 7.60667 15.7394C5.93666 15.7391 4.37039 15.7377 3.21351 15.7349C2.63509 15.7336 2.15899 15.7319 1.82344 15.7297C1.65567 15.7287 1.52308 15.7275 1.43042 15.7261H1.43027C1.38401 15.7255 1.3476 15.7248 1.32178 15.7241L1.29046 15.7229H1.29039L1.28179 15.7224L1.27817 15.722L1.277 15.7219H1.27677H1.27667L1.27658 15.7218H1.27655L1.27674 15.7209H1.27134C1.12055 15.6911 0.964424 15.6243 0.827763 15.5185H0.827755H0.82771H0.827764H0.82781L8.77888 10.7569Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
