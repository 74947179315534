let domain = "vezham";
let setup_env = window.location.host.split(".")[0];
if (setup_env == domain) {
  setup_env = "live";
} else if (setup_env.includes("localhost")) {
  setup_env = "qa";
}
let env = "https://" + (setup_env === "live" ? "" : setup_env + ".");

let is_mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(
  navigator.userAgent.toLowerCase()
);

export let is_beta = setup_env == "qa";

let whatsapp_host = is_mobile
  ? "https://api.whatsapp.com"
  : "https://web.whatsapp.com";
export let whatsapp_api =
  whatsapp_host +
  "/send?phone=+919080664904&text=I'm%20interested%20in%20your%20services";

let host_policies = env + "policies.vezham.com";
export let url_tos = host_policies + "/tos";
export let url_privacy = host_policies + "/privacy";
