<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.41333 7.00006C8.08048 7.00006 7 8.08054 7 9.41339V38.5866C7 39.9195 8.08049 41 9.41333 41H38.5867C39.9195 41 41 39.9195 41 38.5866V9.41339C41 8.08055 39.9195 7.00006 38.5867 7.00006H9.41333ZM14.6314 17.5588C16.2604 17.5588 17.5809 16.2382 17.5809 14.6093C17.5809 12.9803 16.2604 11.6598 14.6314 11.6598C13.0025 11.6598 11.6819 12.9803 11.6819 14.6093C11.6819 16.2382 13.0025 17.5588 14.6314 17.5588ZM20.2822 19.7385H25.1704V21.9779C25.1704 21.9779 26.4969 19.3249 30.1061 19.3249C33.3257 19.3249 35.9928 20.9109 35.9928 25.7453V35.9396H30.9271V26.9806C30.9271 24.1287 29.4046 23.8151 28.2444 23.8151C25.8367 23.8151 25.4245 25.8919 25.4245 27.3526V35.9396H20.2822V19.7385ZM17.2026 19.7386H12.0603V35.9396H17.2026V19.7386Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
