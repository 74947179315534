<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.694 15.694C18.286 16.102 17.6244 16.102 17.2164 15.694L12 10.4776L6.78361 15.694C6.37559 16.102 5.71405 16.102 5.30602 15.694C4.89799 15.286 4.89799 14.6244 5.30602 14.2164L11.2164 8.30602C11.4318 8.09061 11.7179 7.98892 12 8.00096C12.2821 7.98892 12.5682 8.09061 12.7836 8.30602L18.694 14.2164C19.102 14.6244 19.102 15.286 18.694 15.694Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
